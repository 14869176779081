/* Parse inputed URLs and use propper VUE link tags.
If local URL, remove cms.uic.edu and use <router-link> tag.
If external link, leave alove URL and use <a> tag */

export default {
  methods: {
    processUrl(url) {
      let finalUrl = url;
      // Must be url to use URL() function.
      if (finalUrl.match(/^(http(s)?|ftp):\/\//)) {
        const checkUrl = new URL(url);
        // First test, checks if internal link.
        if (checkUrl.host === process.env.VUE_APP_API_URL) {
        // if true turns url into a slug
          finalUrl = checkUrl.pathname;
        } else {
        // if not internal link, then use <a> tag
          return {
            is: 'a',
            href: finalUrl,
            target: '_self',
            rel: 'noopener',
          };
        }
      }
      // if finalURL is instead a slug, use <router-link>
      return {
        is: 'router-link',
        to: finalUrl,
      };
    },
  },
};
