/* Returns truthy expressions and slugs.
Uses Router parameters to check whether the page is
Home, Landing, or Inner. */

export default {
  computed: {
    pageType() {
      const { landing, inner } = this.$route.params;
      let computeHomePage;
      let computeLandingPage;
      let computeInnerPage;
      if (this.$route.name === 'Home') { // Home Page
        computeHomePage = true;
        computeLandingPage = false;
        computeInnerPage = false;
      } else if (this.$route.name === 'Landing') { // Landing Page
        computeHomePage = false;
        computeLandingPage = true;
        computeInnerPage = false;
      } else if (this.$route.name === 'Inner') { // Inner Page
        computeHomePage = false;
        computeLandingPage = false;
        computeInnerPage = true;
      }
      return {
        // Page Types
        homePage: computeHomePage,
        landingPage: computeLandingPage,
        innerPage: computeInnerPage,
        // Page Slugs
        landingName: landing,
        innerName: inner,
      };
    },
  },
};
