<template>

<div class="component">
  <section role="region" class="text-block-with-inline-image" :aria-label="component.title">
    <div class="ms-row header-row">
      <div class="col-1-1">
        <h2 class="interior-section-header" v-html="component.title"></h2>
      </div>
    </div>
    <div class="ms-row intro-row" v-if="component.intro_text">
      <div class="col-1-1" v-html="component.intro_text"></div>
    </div>
    <div class="ms-row">
      <div class="col-1-1">
        <img v-lazy="component.image.url" :alt="component.image.alt" class="image">
        <div class="block-wysiwyg" v-html="component.text"></div>
        <div class="link-list">
          <ul>
            <li class="link-item"
            v-for="(item, index) in component.link_list"
            :key="index">
              <c-link v-bind="processUrl(item.link_url)">
                {{ item.link_text }}
                <ArrowSVG/>
              </c-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>

</template>

<script>

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

export default {
  name: 'TextBlockWInlineImage',
  props: ['component', 'processUrl'],
  components: {
    ArrowSVG,
  },
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/wysiwyg.scss";

.text-block-with-inline-image {
  .header-row {
    margin-bottom: 15px;
  }
  .intro-row {
    margin-bottom: 20px;
  }
  .intro-row,
  .text-and-image-row {
    @include bodyText();
    @include navyHyperlinks();
  }
  .image {
    float: right;
    height: auto;
    margin-left: 30px;
    margin-bottom: 30px;
    width: 100%;

    @media (min-width: $vp2) {
      width: 50%;
    }
  }
  .link-list {
    @include bodyText();

    ul {
      list-style: none inside;
    }

    li {
      margin-bottom: 15px;
    }

    a {
      @include linkWithArrow();
      .arrow {
        margin-left: 5px;
      }
    }
  }
}

</style>
