<template>

<div class="component">

  <CategoryLinkListPart
  :wrapper="wrapper"
  :processUrl="processUrl"
  :component="component">
  </CategoryLinkListPart>

</div>

</template>

<script>

// Components
import CategoryLinkListPart from '@/components/ui-components/CategoryLinkListPart.vue';

export default {
  name: 'CategoryLinkList',
  props: ['processUrl', 'component'],
  components: {
    CategoryLinkListPart,
  },
  data() {
    return {
      wrapper: {
        is: 'section',
        class: 'category-link-list',
        role: 'region',
        aria_label: this.component.title,
      },
    };
  },
};

</script>

<style scoped lang="scss">

</style>
