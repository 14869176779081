<template>

<div class="interior-page-intro">
    <div class="ms-row">
        <div class="col-1-1">
            <h1 class="interior-page-title" v-html="pageContent.title"></h1>
            <div class="interior-page-subtitle" v-html="pageContent.acf.subtitle">
            </div>
            <div class="interior-page-text" v-html="pageContent.content">
            </div>
        </div>
    </div>
</div>

</template>

<script>

export default {
  name: 'IntroInnerPage',
  props: ['pageContent'],
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/interior-page-intro.scss";

</style>
