<template>

<div class="ms-grid main">
  <div class="ms-row">
    <div class="m-col-3-4 m-col-4-offset-1">
      <div class="interior-page-intro">
        <div class="ms-row">
          <div class="col-1-1">
            <h1 class="interior-page-title">Search</h1>
            <div class="interior-page-text">

              <script type="application/javascript" async src="https://cse.google.com/cse.js?cx=009511351313755808885:dkx-7mlm6ni"></script>

              <form class="google-search-form" method="GET" @submit="searchEmptyCheck">
                <input type="text" class="text-input" v-model="search" id="searchTerm" name="q" placeholder="Search...">
                <input type="submit" class="search-button" value="Search">
                <div class="form-group">
                  <input type="checkbox"
                  class="input-checkbox"
                  id="globalSearch"
                  name="global_search"
                  value="true"
                  :checked="globalSearchCheck">
                  <label class="form-element-label" for="globalSearch">Search all UIC sites</label>
                </div>
                <template v-if="errors">
                  <p class="form-error-text" v-html="errors"></p>
                </template>
              </form>
              <div class="google-search-results">
                <div v-if="globalSearchCheck" class="gcse-searchresults-only"></div>
                <div v-if="!globalSearchCheck" class="gcse-searchresults-only" data-as_sitesearch="https://www.uic.edu/"></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>

// SVG
// import SearchGlassSVG from '@/components/ui-components/svg/icon-search-glass.svg';

export default {
  name: 'Search',
  data() {
    return {
      errors: null,
      search: this.$route.query.q,
    };
  },
  // components: {
  //   SearchGlassSVG,
  // },
  computed: {
    globalSearchCheck() {
      let gs = true;
      if (this.$route.query.q === undefined && this.$route.query.global_search === undefined) {
        gs = true;
      } else if (this.$route.query.global_search === undefined) {
        gs = false;
      }
      return gs;
    },
  },
  methods: {
    searchEmptyCheck(event) {
      if (this.search) {
        return true;
      }
      if (!this.search) {
        this.errors = 'What would you like to search?';
      }
      event.preventDefault();
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "@/scss/shared/interior-page-intro.scss";

.google-search-form {
  line-height: 2.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  .form-group {
    width: 100%;
    display: inline-block;
    .input-checkbox{
      margin: 0 0.5rem 0 0;
    }
  }
  .text-input {
    flex: 1 0 auto;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    color: #363636;
    padding: 11px 15px;
    line-height: 1;
    &::focus {
      border-color: #001e62;
      color: #001e62;
    }
    &::placeholder {
      color: #c3c3c3;
    }
  }
  .input-list {
    display: inline-block;
    margin-right: 0.5em;
  }
  .form-element-label {
    display: inline-block;
    margin-right: 1em;
  }
  .search-button {
    @include button(#FFF, $navy);
    margin-left: 1rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='-1 -1 22 22' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3ESearch icon%3C/title%3E%3Cg class='_glass' stroke='%23fff' fill='none' fill-rule='evenodd' stroke-width='2'%3E%3Cellipse cx='8' cy='8' rx='8' ry='8'/%3E%3Cpath d='M19 20l-5-5.9' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    padding-left: 2.66rem;
    background-position: 20px center;
    background-size: 1rem;
  }
  @media screen and (max-width: 540px) {
    .button {
      display: inherit;
    }
  }
  p {
    line-height: initial;
  }
}

.google-search-results {
  margin-top: 1em;
}

@media screen and (max-width: 767px) {
  .main {
    margin-top: 20px;
  }
}

</style>
