<template>
  <div class="ms-grid zero-margin full" v-if="getAcf && getReminder && priorityReminder">
    <aside class="priority-reminder-alert" role="alert" aria-label="Priority Reminder Alert">
      <div class="priority-reminder-row-container">
        <div class="ms-row">

          <c-link class="priority-reminder-container"
          :class="{ 'priority' : priorityReminder }"
          v-bind="processUrl(priorityReminder.acf.url)">
            <div class="reminders-container">
              <AlertSVG/>
              <div class="priority-text">
                <h2 class="priority-header" v-html="priorityReminder.title"></h2>
                <span class="priority-description"
                v-if="priorityReminder">
                  {{ priorityReminder.acf.text }} <ArrowSVG/>
                </span>
              </div>
            </div>
          </c-link>

        </div>
      </div>
    </aside>
  </div>
</template>

<script>

// Mixins
import todaysDate from '@/mixins/todaysDate';
import processUrl from '@/mixins/processUrl';

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';
import AlertSVG from '@/components/ui-components/svg/icon-alert.svg';

export default {

  name: 'CallToAction',
  components: {
    ArrowSVG,
    AlertSVG,
  },
  mixins: [todaysDate, processUrl],
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getHome() {
      return this.getAcf.uic_home;
    },
    getReminder() {
      return this.$store.state.reminder;
    },
    priorityReminder() {
      const assignedPosts = this.getHome.priority_reminder;
      const postList = this.getReminder;
      let post = null;
      // Check if there are any assigned posts to Campus Reminders
      if (Object.keys(assignedPosts).length > 0) {
        // Organize assignments by post activation (start date, hour, minute).
        assignedPosts.sort((a, b) => a.start_date > b.start_date
          || a.start_hour > b.start_hour
          || a.start_minute > b.start_minute);
        // Loop through all reminder assignments
        for (let i = Object.keys(assignedPosts).length - 1; i > -1; i -= 1) {
          // Format each assigned start date
          const startDate = new Date(`${assignedPosts[i].start_date} ${assignedPosts[i].start_hour}:${assignedPosts[i].start_minute}:00`);
          // Format each assigned expire date
          const expireDate = new Date(`${assignedPosts[i].expire_date} ${assignedPosts[i].expire_hour}:${assignedPosts[i].expire_minute}:00`);
          /* Compare activation date and expiration date to todays date and time.
          If not yet active or expired assignment: skip and check next one */
          if (startDate > this.todaysDate || expireDate < this.todaysDate) {
            continue;
          }
          // Find post content from active assignment with same ID and push into 'posts' array
          Object.keys(postList).forEach((x) => {
            if (postList[x].ID === assignedPosts[i].reminder.ID) {
              post = postList[x];
            }
          });
          // Stop the For loop once post is found
          break;
        }
      }
      return post;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.priority-reminder-alert {
  background-color: #FFF;
  font-size: 16px;
  line-height: 20px;
  .priority-reminder-row-container {
    margin: 0 auto;
    padding: 0px;

    @media (min-width: $siteContentWidth + 60px) {
      padding: 0px;
    }
  }
  .priority-reminder-container {
    width: 100%;
    text-decoration: none;
    &:hover,
    &:focus {
      .arrow {
        @include arrowActiveState;
      }
    }
  }
  .reminders-container {
    display: flex;
    align-items: center;
    // font-weight: 700;
    font-size: 18px;
    min-height: 60px;
    max-width: 1160px;
    margin: 0 auto;

    @media (max-width: $vp4h) {
      max-width: 1080px;
    }
    @media (max-width: $vp4) {
      padding: 10px 20px;
    }
  }
  .reminders {
    fill: $red;
    height: 30px;
    width: 30px;
  }
  .priority {
    background: $gold;

    @media (max-width: $vp3 - 1px) {
      width: 100%;
    }
    .reminders-container {
      flex-direction: row;
      overflow: hidden;
      width: auto;
      .reminders {
        fill: #FFF;
      }
      .alert-icon {
        fill: $navy;
        height: 40px;
        // margin-right: 20px
        padding: 0 15px 0 5px;
      }
      .priority-header {
        color: $navy;
        font-size: 18px;
        width: 100%;
        text-transform: uppercase;
        @media (max-width: $vp2) {
          font-size: 15px;
        }
      }
      .priority-description,
      .priority-description:hover,
      .priority-description:visited {
        color: $navy;
      }
      .priority-description {
        width: 100%;
        @media (max-width: $vp2) {
          font-size: 13px;
        }
        .arrow {
          @include arrowTransition;
          fill: $navy;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
}

</style>
