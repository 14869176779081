<template>

  <div :class="{ loader: true, fadeout: checkStatusLoaded }">
    <div class="loading-screen">
      <div class="group-loading-items">
        <CircleLogoSVG/>
        <div class="isolate-loading-animation">
          <span class="loading-animation"></span>
        </div>
      </div>
    </div>
    <template v-if="checkStatusLoaded === true">
      {{ spaPrerenderTrigger }}
    </template>
  </div>

</template>

<script>

import CircleLogoSVG from '@/components/ui-components/svg/logo-circle.svg';

export default {
  name: 'LoadingScreen',
  components: {
    CircleLogoSVG,
  },
  // data() {
  //   return {
  //     checkStatusLoaded: true,
  //   }
  // },
  props: [
    'checkStatusLoaded',
    'spaPrerenderTrigger'
  ],
};

</script>

<style scoped lang="scss">

$size-body: 20px;
$bg-body: #f8f8f8;
$color-gray: #363636;
$color-red: #d50032;

.loader {
  background-color: $bg-body;
  opacity: 1;
  bottom: 0;
  color: black;
  display: block;
  font-size: $size-body;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99999999999;
}

.fadeout {
  animation: fadeout 0.5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.loading-screen {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.group-loading-items {
  top: calc(50vh - 100px);
  position: absolute;
  width: 100%;
}

.isolate-loading-circle {
  width: 100%;
}

.circle-logo {
  height: 80px;
  margin-bottom: 22px;
  fill: $red;
}

.loading-animation {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 $red, 24px 0 #fff;
  -webkit-animation: circleCycle 2s linear infinite;
  animation: circleCycle 2s linear infinite;
}

@keyframes circleCycle {
  33% {
    background: $red;
    box-shadow: -24px 0 #fff, 24px 0 #FFF;
  }
  66% {
    background: #fff;
    box-shadow: -24px 0 #FFF, 24px 0 $red;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 $red, 24px 0 #fff;
  }
}

</style>
