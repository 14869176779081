<template>

<main v-if="apiPage && apiMenus && apiLandingPage">

  <h1 id="home-page-title">University of Illinois Chicago</h1>

  <div class="ms-grid main">
    <div class="ms-row">

      <!-- Sidebar Nav -->
      <div class="m-col-1-4">
        <nav class="navigation-sidebar"
        :class="{ 'open': isOpen }"
        aria-label="Secondary"
        v-click-outside="focusOutMenu">
          <h2 class="navigation-sidebar-title js-sidebar-toggle" v-on:click="toggle()">
            <span class="chevron">
              <svg class="anchor-point" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 8"><path d="M6 8L.4 2.2C-.1 1.7-.1.9.4.4s1.3-.5 1.8 0l3.9 4 3.9-4c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8L6 8z"></path></svg>
            </span>
            <c-link v-bind="processUrl(sideMenu.parent.url)"
            v-html="sideMenu.parent.title">
            </c-link>
          </h2>
          <div class="navigation-sidebar-list-container">
            <ul class="navigation-sidebar-list">
              <c-link v-for="(item, index) in sideMenu.children"
              tag="li"
              v-bind="processUrl(item.url)"
              active-class="active"
              :key="index"
              class="navigation-sidebar-item">
                <a class="navigation-sidebar-anchor" v-html="item.title">
                </a>
              </c-link>
            </ul>
          </div>
        </nav>
      </div>
      <!-- Sidebar Nav | end -->

      <div class="m-col-3-4">

        <!-- Inner Page Breadcumbs Nav -->
        <nav class="breadcrumbs ms-row" v-if="pageType.innerPage">
          <div class="col-1-1">
            <router-link class="red-circle-link" :to="{ name: 'Home' }">
              <span v-show="false">Home</span>
              <div class="red-circle" aria-hidden="true"></div>
            </router-link>
            <BreadcrumbArrowSVG class="first-breadcrumb-arrow"/>
            <ul class="crumb-list">
              <li class="crumb-item" v-if="pageType.landingName">
                <router-link class="crumb-anchor"
                :to="{ name: 'Landing' }"
                v-html="pageType.landingName">
                </router-link>
              </li>
              <BreadcrumbArrowSVG/>
              <li class="crumb-item" v-if="pageType.innerName">
                <router-link class="crumb-anchor"
                active-link="active"
                active-class="active"
                :to="{ name: 'Inner' }"
                v-html="pageType.innerName">
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
        <!-- Inner Page Breadcumbs Nav | end -->

        <LandingPageIntro :pageContent="pageContent"
        :processUrl="processUrl"
        v-if="pageType.landingPage">
        </LandingPageIntro>

        <InnerPageIntro :pageContent="pageContent" v-if="pageType.innerPage"></InnerPageIntro>

        <!-- Components -->
        <template v-if="pageContent.acf.content_components.length > 0">
          <component
          v-for="(component, index) in pageContent.acf.content_components"
          :key="index"
          :is="nameComponents(component.acf_fc_layout)"
          :component="component"
          :processUrl="processUrl">
          </component>
        </template>
        <!-- Components | end -->

      </div>

    </div>
  </div>

</main>

</template>

<script>

// Mixins
import pageType from '@/mixins/pageType';
import pageContent from '@/mixins/pageContent';
import processUrl from '@/mixins/processUrl';
import mainMenu from '@/mixins/mainMenu';

// SVG
import BreadcrumbArrowSVG from '@/components/ui-components/svg/breadcrumb-arrow.svg';

// Components
import LandingPageIntro from '@/components/ui-components/LandingPageIntro.vue';
import InnerPageIntro from '@/components/ui-components/InnerPageIntro.vue';
import TextBlockWInlineImage from '@/components/ui-components/TextBlockWInlineImage.vue';
import TextBlockWLargeImage from '@/components/ui-components/TextBlockWLargeImage.vue';
import Highlight from '@/components/ui-components/Highlight.vue';
import MapHighlight from '@/components/ui-components/MapHighlight.vue';
import TopicBlockList from '@/components/ui-components/TopicBlockList.vue';
import LinkList from '@/components/ui-components/LinkList.vue';
import CategoryLinkList from '@/components/ui-components/CategoryLinkList.vue';

export default {
  name: 'Body',
  components: {
    LandingPageIntro,
    InnerPageIntro,
    TextBlockWLargeImage,
    TextBlockWInlineImage,
    Highlight,
    MapHighlight,
    TopicBlockList,
    LinkList,
    CategoryLinkList,
    BreadcrumbArrowSVG,
  },
  mixins: [pageType, pageContent, processUrl, mainMenu],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    apiLandingPage() {
      return this.$store.state.landingPage;
    },
    apiPage() {
      return this.$store.state.page;
    },
    apiMenus() {
      return this.$store.state.menus;
    },
    sideMenu() {
      const { landing } = this.$route.params;
      const menuItems = this.mainMenu;
      let finalSideMenu;
      Object.keys(menuItems).forEach((i) => {
        const itemSlug = this.processUrl(menuItems[i].parent.url).to;
        const currentSlug = `/${landing}/`;
        if (itemSlug === currentSlug) {
          finalSideMenu = menuItems[i];
        }
      });
      return finalSideMenu;
    },
  },
  methods: {
    nameComponents(component) {
      let name = '';
      switch (component) {
        case 'text_large_image':
          name = 'TextBlockWLargeImage';
          break;
        case 'text_inline_image':
          name = 'TextBlockWInlineImage';
          break;
        case 'highlight':
        case 'quote_highlight':
          name = 'Highlight';
          break;
        case 'map_highlight':
          name = 'MapHighlight';
          break;
        case 'topic_block_list':
          name = 'TopicBlockList';
          break;
        case 'link_list':
          name = 'LinkList';
          break;
        case 'category_link_list':
          name = 'CategoryLinkList';
          break;
        case 'form':
          name = 'Form';
          break;
        default:
          break;
      }
      return name;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    focusOutMenu() {
      this.isOpen = false;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

// Breadcrumbs

.breadcrumbs {
  line-height: 14px;
  margin-bottom: 34px;
  .red-circle-link {
    margin-left: 0; // FIXs
  }
  .red-circle:hover {
    background-color: darken($red, 20.78%);
  }
  .red-circle {
    @media (min-width: $vp3) {
      background: $red;
      border-radius: 50%;
      display: inline-block;
      height: 14px;
      width: 14px;
      vertical-align: middle;
    }
  }
  .first-breadcrumb-arrow {
    display: none;

    @media (min-width: $vp3) {
      display: inline;
    }
  }
  .crumb-list {
    display: inline;
    list-style: inside none;

    @media (max-width: $vp3) {
      line-height: 1.4;
    }
  }
  .crumb-item {
    display: inline-block;
  }
  .crumb-anchor {
    color: $charcoal;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .crumb-anchor,
  .crumb-anchor:hover,
  .crumb-anchor:visited {
    text-decoration: none;
  }
  .crumb-anchor:hover,
  .crumb-anchor.active,
  .crumb-anchor:focus {
    color: $red;
    outline: none;
  }

  svg {
    display: inline-block;
    height: 6px;
    margin: 0px 14px;
    width: 6px;
    vertical-align: middle;
  }
}

// Navigation Sidebar

$sidebarIndent: 14px;
$touchMenuBreakpoint: 768px;
$pageGutter: 20px;.navigation-sidebar {
  @include secondaryNavigation($touchMenuBreakpoint, $sidebarIndent, $pageGutter);
}

</style>
