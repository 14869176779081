<template>

<main>

  <h1 id="home-page-title">University of Illinois Chicago</h1>

  <div class="ms-grid main">
    <div class="ms-row">

      <!-- 404 -->
      <div class="m-col-3-4 m-col-4-offset-1">
        <div class="interior-page-intro">
          <div class="ms-row">
            <div class="col-1-1">
              <h1 class="interior-page-title">Page Not Found</h1>
              <div class="interior-page-subtitle">
                We’re sorry, the page that you requested was not found.
              </div>
            </div>
          </div>
        </div>
        <div class="component">
          <div class="error-help">
            <p class="double-gap-bottom">Possible reasons for not finding your page:</p>
            <ul class="error-help-list double-gap-bottom">
              <li class="error-help-list-item">
                You typed in the URL and made a typo.
                You could try retyping it, remembering that URLs are typically case-sensitive.
              </li>
              <li class="error-help-list-item">
                The page may have been deleted or a link from one of our pages may be broken.
                Please send the webmaster a note and include the URL of the page you are viewing and
                the link that was broken.
              </li>
            </ul>
            <p class="double-gap-bottom">
              You are welcome to explore our site via the other links on this page, or try a search.
              If that doesn't help, please <a href="mailto:www@uic.edu">contact us</a>.
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

</main>

</template>

<script>

export default {
  name: 'NotFound',
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "@/scss/shared/interior-page-intro.scss";

.error-help {
  @include bodyText();
  @include navyHyperlinks();
  .error-help-list {
    list-style: none inside;
  }
  .error-help-list-item {
    padding-left: 26px;
    position: relative;
    &:before {
      background-color: $red;
      content: " ";
      height: 6px;
      left: 0px;
      position: absolute;
      top: calc(0.58em - 3px);
      width: 6px;
    }
  }
  .help-link-list {
    ul {
      list-style: none inside;
    }
    .link-item {
      margin-bottom: 18px;
    }

    a {
      @include linkWithArrow();
      color: $charcoal;
      font-weight: 400;
      &:hover,
      &:visited {
        color: $charcoal;
      }
      .arrow {
        fill: $red;
      }
    }
  }
}

</style>
