<template>
  <div class="ms-grid zero-margin full" v-if="getAcf">
    <aside class="calls-to-action" role="complementary" aria-label="Calls to Action">
      <div class="calls-to-action-row-container">
        <div class="ms-row call-to-action-row cta-special">
          <div class="calls-to-action-group-container">
            <ul class="calls-to-action-group-container-again">
              <li class="xs-col-1-2 m-col-1-3 cta-4-column"
              v-for="(item, index) in getCallToAction"
              :key="index">
                <c-link class="call-to-action" v-bind="processUrl(item.url)">
                  <div class="cta0">
                    <div class="cta1">
                        <div class="call-to-action-icon-container" aria-hidden="true">
                          <IconSVG :is="IconSVG(item.icon)"/>
                        </div>
                    </div>
                    <div class="cta2">
                      <div class="call-to-action-label" v-html="item.text">
                      </div>
                    </div>
                  </div>
                </c-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>

// Mixins
import processSVG from '@/mixins/processSVG';
import processUrl from '@/mixins/processUrl';

// SVG
import LuggageSVG from '@/components/ui-components/svg/icon-travel-luggage.svg';
import MonitorSVG from '@/components/ui-components/svg/icon-desktop-monitor-download.svg';
import MouseSVG from '@/components/ui-components/svg/icon-mouse.svg';
import GradSVG from '@/components/ui-components/svg/icon-people-man-graduate.svg';
import FlagSVG from '@/components/ui-components/svg/icon-flag.svg';
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';
import AnchorPointSVG from '@/components/ui-components/svg/icon-anchor-point.svg';

export default {

  name: 'CallToAction',
  components: {
    LuggageSVG,
    MonitorSVG,
    MouseSVG,
    GradSVG,
    FlagSVG,
    ArrowSVG,
    AnchorPointSVG,
  },
  mixins: [processSVG, processUrl],
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getCallToAction() {
      return this.getAcf.uic_home.call_to_action;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.calls-to-action {
  font-size: 16px;
  line-height: 20px;
  height: 200px;
  padding-bottom: 60px;
  padding-top: 60px;
  @media (max-width: $vp3) {
    height: 140px;
    padding-bottom: 0px;
    padding-top: 35px;
  }
  @media (max-width: $vp2) {
    height: 100px;
    padding-bottom: 0px;
    padding-top: 20px;
  }
  .call-to-action-row {
    list-style: none;
  }
  .calls-to-action-row-container {
    margin: 0 auto;
    max-width: $siteMaxWidth;
    padding: 0px;

    @media (min-width: $siteContentWidth + 60px) {
      padding: 0px;
    }
  }
  .calls-to-action-group-container {
    float: left;
    padding: 0px 0px 0px $smallGutter;
    position: relative;
    width: 80%;
    min-height: 80px;
    margin: 0 auto;
    @media (max-width: $vp4) {
      width: 80%;
      padding: 0;
    }[class*=col-] {
      padding-left: 0;
      padding-right: 0;
    }
    @media (max-width: $vp3) {
      width: 90%;
    }
    @media (max-width: $vp2) {
      width: 95%;
      padding: 0;
    }
  }
  ul.calls-to-action-group-container-again {
    list-style: none;
    margin: 0 auto;
    width: 100%;
    .cta-4-column {
      width: 25%;
    }

    @media (min-width: $vp3) {
      width: 100%;
    }

    @media (min-width: $siteContentWidth + $mediumGutter * 2) {
      position: relative;
      right: 0px;
      // width: $siteContentWidth / 2;
    }
  }
  .calls-to-action-reminder-container {
    float: right;
    padding: 0 $smallGutter;
    width: 100%;

    @media (min-width: $vp3) {
      width: 50%;
    }

    @media (min-width: $vp3) and (max-width: $siteMaxWidth) {
      padding: 0px $mediumGutter;
    }
  }
  .call-to-action {
    display: flex;
    align-items: center;
    color: $navy;
    font-weight: 700;
    height: 80px;
    padding: 0px 10px 0px 0px;
    position: relative;
    text-decoration: none;
    .cta0 {
      width: 100%;
      &:hover > .cta1 .call-to-action-icon-container svg {
        stroke: $navy;
      }
      .cta1 {
        margin: 0 auto;
        height: 70px;
        width: 70px;
        margin-bottom: 10px;
        @media (max-width: $vp4) {
          height: 60px;
          width: 60px;
        }
        @media (max-width: $vp3) {
          height: 50px;
          width: 50px;
          margin-bottom: 0;
        }
        @media (max-width: $vp2) {
          height: 30px;
          width: 30px;
          margin-bottom: 0;
        }
      }
      .cta2 {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 18px;
        margin-top: 17px;
        @media (max-width: $vp4) {
          font-size: 16px;
          letter-spacing: .5px;
          margin-top: 13px;
        }
        @media (max-width: $vp3) {
          font-size: 14px;
          letter-spacing: .3px;
          margin-top: 10px;
        }
        @media (max-width: $vp2) {
          font-size: 12px;
          height: 30px;
          letter-spacing: 0;
          line-height: 130%;
        }
      }
    }

    @media (min-width: $vp3) {
      height: 80px;
    }
    &:hover,
    &:visited {
      color: $navy;
      text-decoration: none;
    }
    .call-to-action-icon-container {
      flex: 0 0 auto;
      flex-shrink: 0;
      margin: 0 auto;
      height: 70px;
      width: 70px;
      &:before {
        border-radius: 50%;
        border: none;
      }
      @media (max-width: $vp4) {
        height: 60px;
        width: 60px;
      }
      @media (max-width: $vp3) {
        height: 50px;
        width: 50px;
      }
      @media (max-width: $vp2) {
        height: 30px;
        width: 30px;
      }
    }

    svg {
      fill: none;
      stroke: $red;
      position: relative;
      height: 100%;
      width: 100%;
      &:hover {
        stroke: $navy;
      }
    }
    .call-to-action-label {
      color: $navy;
      flex: 1 1 auto;
      margin-left: 0;
    }
  }
  .reminders-container {
    display: flex;
    align-items: center;
    font-weight: 700;
    height: 80px;

    @media (min-width: $vp3) {
      flex-direction: row-reverse;
    }

    @media (min-width: $siteContentWidth + $mediumGutter * 2) {
      width: $siteContentWidth / 2;
    }
  }
  .reminders-icon-container {
    display: block;
    height: 30px;
    margin-right: 10px;
    width: 30px;
    &:before {
      border-radius: 50%;
      border-color: $red;
    }
  }
  .reminders {
    fill: $red;
    height: 30px;
    width: 30px;
  }
  .reminders-anchor {
    color: $red;
    display: flex;
    line-height: 30px;
    position: relative;
    text-decoration: none;

    @media (min-width: $vp3) {
      float: right;
      padding-left: 10px;
      padding-right: 22px;
    }
    &:hover,
    &:visited {
      color: $red;
      text-decoration: none;
    }
  }
  .anchor-point {
    fill: $red;
    height: 8px;
    width: 12px;

    @media (min-width: $vp3) {
      position: absolute;
      right: 0px;
      top: 11px;
    }
  }
  .priority {
    background: $red;

    @media (max-width: $vp3 - 1px) {
      width: 100%;
    }
    .reminders-container {
      flex-direction: row;
      overflow: hidden;
      width: auto;
      .reminders-icon-container:before {
        border-color: #FFF;
      }
      .reminders {
        fill: #FFF;
      }
      .reminders-anchor,
      .reminders-anchor:hover,
      .reminders-anchor:visited {
        color: #FFF;
      }
      .reminders-anchor {
        display: none;

        @media (min-width: $vp3) {
          display: flex;
          align-items: center;
          height: 80px;
          line-height: 1.25;
          min-width: 150px;
        }
      }
      .anchor-point {
        fill: #FFF;
        position: static;
      }
      .priority-reminder,
      .priority-reminder:hover,
      .priority-reminder:visited {
        color: #FFF;
        text-decoration: none;
      }
      .priority-reminder {
        width: 100%;
        .arrow {
          @include arrowTransition;
          fill: #FFF;
          height: 10px;
          width: 10px;
        }
        &:hover,
        &:focus {
          .arrow {
            @include arrowActiveState;
          }
        }
      }
    }
  }
}
@include rippleOut('.call-to-action-icon-container', '.call-to-action');
@include rippleOut('.reminders-icon-container', '.reminders-anchor');
.no-flexbox {
  .calls-to-action {
    .call-to-action {
      display: block;
      padding-top: 25px;
    }
    .call-to-action-icon-container {
      float: left;
      margin-right: 10px;
    }
    .priority-reminder {
      line-height: 80px;
    }
    .reminders-anchor {
      display: block !important;
      padding-top: 25px;
    }
    .reminders-icon-container {
      float: left;
    }
    .anchor-point {
      display: none;
    }
  }
}

</style>
