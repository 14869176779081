<template>

<div class="component">
  <section role="region" class="topic-block-list" :aria-label="component.title">
    <div class="ms-row">
      <div class="col-1-1">
        <h2 class="interior-section-header" v-html="component.title"></h2>
      </div>
    </div>
    <div class="ms-row">
      <div class="col-1-1 text-cell" v-html="component.text">
      </div>
    </div>
    <div class="ms-row"
    v-for="(block, index) in component.topic_block"
    :key="index">
      <div class="m-col-1-1">
        <div class="topic-block">
          <div class="ms-row zero-margin">
            <div class="s-col-1-3"
            :class="{'s-col-3-push-2' : index % 2 !== 0}">
              <img class="topic-block-image" v-lazy="block.image.url" :alt="block.image.alt">
            </div>
            <div class="s-col-2-3"
            :class="{'s-col-3-pull-1' : index % 2 !== 0}">
              <div class="topic-block-content"
              :class="{
                'topic-block-content-left' : index % 2 === 0,
                'topic-block-content-right' : index % 2 !== 0}
              ">
                <h3 class="subhead" v-html="block.title"></h3>
                <div class="topic-block-text-cell" v-html="block.text">
                </div>
                <div>
                  <c-link class="link-with-arrow" v-bind="processUrl(block.link_url)">
                    {{ block.link_text }}
                    <ArrowSVG/>
                  </c-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

</template>

<script>

import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

export default {
  name: 'TopicBlockList',
  props: ['processUrl', 'component'],
  components: {
    ArrowSVG,
  },
};

</script>

<style scoped lang="scss">

.topic-block-list {
  .interior-section-header {
    margin-bottom: 15px;
  }
  .topic-block {
    border-top: 1px solid gray;
    padding: 25px 0px;
  }
  .subhead {
    margin-bottom: 10px;
  }
  .topic-block-content {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .text-cell,
  ::v-deep.topic-block-text-cell {
    @include bodyText();
    @include navyHyperlinks();
  }
  .text-cell {
    margin-bottom: 40px;
  }
  .topic-block-text-cell {
    margin-bottom: 15px;
  }
  .topic-block-content-left {
    @media (min-width: $vp2) {
      padding-left: 40px;
    }
  }
  .topic-block-content-right {
    @media (min-width: $vp2) {
      padding-right: 40px;
    }
  }
  .topic-block-image {
    border-radius: 50%;
    width: 100%;

    @media (max-width: $vp2) {
      display: block;
      margin: 0 auto;
      max-width: 240px;
    }

    @media (min-width: $vp2) {
      margin-top: 35px;
    }
  }
}

</style>
