/* Filter out any non menu items in menu object */

export default {
  methods: {
    processMenuItems(menu) {
      const finalArray = [];
      Object.keys(menu).forEach((i) => {
        if ('post_type' in menu[i] && menu[i].post_type === 'nav_menu_item') {
          finalArray.push(menu[i]);
        }
      });
      return finalArray;
    },
  },
};
