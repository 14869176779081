<template>

<div class="component">
  <section class="no-background"
  role="region"
  :aria-label="component.title">
    <div class="quad-gap-bottom">
      <div class="highlight no-background">
        <div class="ms-row zero-margin">
          <div class="l-col-1-2">
            <div class="highlight-image">
              <c-link v-bind="processUrl(component.map_link)">
                <span class="image-cell no-height" role="link">
                  <img class="image smaller-image object-fit-cover zoomable"
                  @mouseout="resetZoom"
                  @mouseenter="zoomIn"
                  @mousemove="setTransformOriginFromMouseEvent"
                  v-lazy="component.map_image.url"
                  :alt="component.map_image.alt">
                </span>
              </c-link>
            </div>
          </div>
          <div class="l-col-1-2 highlight-vertical-center">
            <div class="image-cell no-height right-half-image-cell">
              <img class="image smaller-image object-fit-cover"
              v-lazy="component.neighborhood_image.url"
              :alt="component.neighborhood_image.alt">
            </div>
          </div>
        </div>
      </div>
    </div>

    <CategoryLinkListPart
    :wrapper="wrapper"
    :processUrl="processUrl"
    :component="component">
    </CategoryLinkListPart>

  </section>
</div>

</template>

<script>

// Components
import CategoryLinkListPart from '@/components/ui-components/CategoryLinkListPart.vue';

export default {
  name: 'MapHighlight',
  props: ['processUrl', 'component'],
  data() {
    return {
      wrapper: {
        is: 'div',
        class: 'category-link-list',
      },
      zoomFactor: 2,
    };
  },
  components: {
    CategoryLinkListPart,
  },
  methods: {
    resetZoom(event) {
      TweenLite.set(event.target, { scale: 1 });
    },
    zoomIn(event) {
      TweenLite.set(event.target, { scale: this.zoomFactor });
    },
    setTransformOriginFromMouseEvent(event) {
      const nodeClientRect = event.target.getBoundingClientRect();
      const widthOfNode = nodeClientRect.width;
      const heightOfNode = nodeClientRect.height;
      const topOfNode = nodeClientRect.top + window.scrollY;
      const leftOfNode = nodeClientRect.left + window.scrollX;
      const mouseX = event.pageX;
      const mouseY = event.pageY;
      const percentageOfX = (mouseX - leftOfNode) / widthOfNode;
      const percentageOfY = (mouseY - topOfNode) / heightOfNode;
      const transformOrigin = `${this.convertToPercentageString(percentageOfX)} ${this.convertToPercentageString(percentageOfY)}`;
      TweenLite.set(event.target, { transformOrigin });
    },
    convertToPercentageString(percentageNumber) {
      if (percentageNumber < 0) {
        return '0%';
      }
      return `${String(Math.round(percentageNumber * 100))}%`;
    },
  },
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/highlight.scss";

</style>
