<template>

<!-- Wrapper should at least pass down 'is' attribute value.
  VUE component require a single root element
  and Category Link List is used in at least two locations with different wrappers
  (CategoryLinkList.vue & MapHighlight.vue) -->
<wrapper
:is="wrapper.is"
:class="wrapper.class"
:role="wrapper.role"
:aria-label="wrapper.aria_label">
  <div class="ms-row">
    <div class="col-1-1">
      <h2 class="interior-section-header" v-html="component.title" v-if="component.title"></h2>
    </div>
  </div>
  <div class="ms-row">
    <div class="col-1-1 text-cell" v-html="component.text">
    </div>
  </div>
  <div class="ms-row">
    <div class="col-1-3"
    v-for="(item, index) in component.category_of_links"
    :key="index">
      <div class="category">
        <h2 class="subhead" v-html="item.category_title" v-if="item.category_title"></h2>
        <ul>
          <li class="link-item"
          v-for="(link, index) in item.link"
          :key="index">
            <c-link v-bind="processUrl(link.url)" v-html="link.link_text">
            </c-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</wrapper>

</template>

<script>

export default {
  name: 'CategoryLinkListPart',
  props: ['processUrl', 'component', 'wrapper'],
};

</script>

<style scoped lang="scss">

.category-link-list {
  .interior-section-header {
    margin-bottom: 15px;
  }
  ::v-deep.text-cell {
    @include bodyText();
    @include navyHyperlinks();
    margin-bottom: 30px;
  }

  ul {
    list-style: none inside;
  }
  .subhead,
  .link-item {
    margin-bottom: 6px;
  }
  .category {
    margin-bottom: 20px;
  }
  .link-item {
    a {
      display: inline-block;
      padding: 3px 0;
      @include underlineFromBelow();
    }

    a,
    a:hover,
    a:focus,
    a:visited {
      color: $red;
      font-weight: 400;
      outline: none;
      text-decoration: none;
    }
  }
}

</style>
