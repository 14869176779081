/* Grabs landing and inner page content based on routing slugs and page type */
import processUrl from '@/mixins/processUrl';
import pageType from '@/mixins/pageType';

export default {
  mixins: [
    processUrl,
    pageType,
  ],
  computed: {
    apiLandingPage() {
      return this.$store.state.landingPage;
    },
    apiPage() {
      return this.$store.state.page;
    },
    pageContent() {
      let content;
      if (!this.pageType.homePage && (this.pageType.landingPage || this.pageType.innerPage)) {
        let currentPage;
        let currentSlug;
        if (this.pageType.landingPage) {
          currentPage = this.apiLandingPage;
          currentSlug = `/${this.pageType.landingName}/`;
        } else if (this.pageType.innerPage) {
          currentPage = this.apiPage;
          currentSlug = `/${this.pageType.landingName}/${this.pageType.innerName}/`;
        }
        Object.keys(currentPage).forEach((i) => {
          const slug = this.processUrl(currentPage[i].link);
          if (slug.to === currentSlug) {
            content = currentPage[i];
          }
        });
      }
      return content;
    },
  },
};
