<template>

  <div ref="headerContent"
  class="header-content"
  :class="{ 'homepage-header-content': pageType.homePage, 'video-content': hero.acf.header_type == 'Video'  }"
  v-if="pageType.homePage || pageType.landingPage">

    <div class="hero-image-container">
      <template v-if="(hero.acf.header_type == 'Image') || pageType.landingPage">
        <div class="hero-image"
        :style="{ backgroundImage: `url(${hero.acf.hero_image.url}` }"
        :key="hero.acf.hero_image.url"
        alt="Hero Image"
        ref="blah">
        </div>
      </template>

      <template v-if="hero.acf.header_type == 'Video'">
        <div id="video-wrapper">
          <iframe :src="urlStart + videoID + videoParams" title="Decorative background video">
          </iframe>
          <div class="pause-overlay">
            <div class="pause-button">
              <VideoPlayButtonSVG/>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="page-title-container hero-image-with-title" v-if="pageType.landingPage">
      <div class="page-title-container-inner">
        <h1 class="landing-page-title" v-html="hero.title"></h1>
      </div>
    </div>

    <div class="ms-grid home-page-news-intro-position">
      <article class="home-page-news-intro"
      role="article"
      v-if="availableNews && (hero.acf.intro_type == 34)">
        <a class="home-page-news-intro-anchor" :href="hero.acf.link_url">
          <h1 class="home-page-news-intro-headline" v-html="hero.acf.headline"></h1>
          <p class="home-page-news-intro-text">{{hero.acf.link_text}} <strong>More</strong>
            <ArrowSVG/>
          </p>
        </a>
      </article>
    </div>
  </div>

</template>

<script>

import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';
import VideoPlayButtonSVG from '@/components/ui-components/svg/video-play-button.svg';

export default {
  name: 'HeaderHero',
  props: ['hero', 'pageType', 'availableNews'],
  components: {
    ArrowSVG,
    VideoPlayButtonSVG,
  },
  data() {
    return {
      data: null,
      urlStart: 'https://player.vimeo.com/video/', // video source url
      videoID: `${this.hero.acf.video_id}`, // video ID pulled from CMS
      videoParams: '?autoplay=1&loop=1&muted=1&controls=0&background=1', // video parameters
    };
  },
  mounted() {
    /*
      VIMEO click-to-pause overlay
      orig concept borrowed from https://codepen.io/ezra_siton/pen/LYYWQLX?editors=1111
      with heavy modifications (e.g. now vanilla js instead of jQuery)
    */
    if (this.hero.acf.header_type == 'Video') {
      const iframe = document.querySelector('iframe');
      let overlayDiv = document.querySelector('.pause-overlay');
      let player = new Vimeo.Player(iframe);
      player.on('play', function () {
        overlayDiv.style.display = 'none';
      });
      player.unload().then(function () {
        overlayDiv.style.display = '';
      });
      player.on('pause', function () {
        overlayDiv.style.display = '';
      });
      /* custom click event for the overlay */
      overlayDiv.addEventListener('click', function (event) {
        document.querySelector('.pause-overlay').style.display = 'none';
        overlayDiv.style.display = '';
        player.setVolume('0');
        player.pause();
      }, false);
      overlayDiv.addEventListener('click', function (event) {
        document.querySelector('.pause-overlay').style.display = '';
        overlayDiv.style.display = 'none';
        player.setVolume('0');
        player.play();
      }, false);
    }
  },
};

</script>

<style scoped lang="scss">

.header-content {
  margin: 0 auto;
  // min-height: 700px;
  max-width: $siteMaxWidth;
  position: relative;
}
.video-content {
  max-width: none;
}

.page-title-container {
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;

  @media (min-width: $vp4) {
    height: 100%;
  }
  .page-title-container-inner {
    height: 100%;
    @include centerfy();
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;

    @media (min-width: $vp3) and (max-width: $vp4) {
      height: 600px;
    }

    @media (min-width: $vp2) and (max-width: $vp3) {
      height: 455px;
    }

    @media (max-width: $vp2) {
      height: 250px;
    }
  }
  .header-brand-mark {
    bottom: 0px;
    height: $mobileHeaderHeight;
    padding: 20px;
    position: absolute;
    width: 100%;

    @media (min-width: $vp3) {
      bottom: 30px;
      height: auto;
      left: 30px;
      right: 30px;
      top: 30px;
      padding: 0px;
    }

    @media (min-width: $vp4) {
      position: static;
      width: 100%;
    }
  }
}

.hero-image-container {
  overflow: hidden;
  min-height: 200px;
  margin-bottom: 19px;
  @media(max-width: $vp3) {
    margin-bottom: 0;
  }
}
.hero-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  left: 50%;
  margin: 0 auto;
  position: relative;
  transform: translateX(-50%);
  width: auto;
  @include headerHeights();
}
.scrolled-before .hero-image {
  @media (min-width: $siteMaxWidth) {
    left: 0px;
    transform: none;
  }
}

// Home Page News Intro
.home-page-news-intro-position {
  @media(max-width: $vp3) {
    position: unset;
    top: 0;
    bottom: 0;
  }
  .home-page-news-intro {
    background: $blue;
    color: #FFF;
    min-height: 170px;
    padding: 20px 30px 30px;
    bottom: 24px;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    max-width: 60%;
    @media (max-width: $vp4) {
      margin: unset;
      right: unset;
    }
    @media (max-width: $vp3) {
      min-height: unset;
      max-width: 80%;
      bottom: 0;
      left: 20px;
      right: 20px;
    }
    @media (max-width: $vp2) {
      padding: 10px 20px 20px;
      min-height: unset;
      max-width: 100%;
      right: 15px;
      left: 15px;
    }
    .home-page-news-intro-anchor {
      color: #FFF;
      display: block;
      margin: 0 auto;
      max-width: $siteContentWidth;
      text-decoration: none;
      &:hover,
      &:visited {
        color: #FFF;
        text-decoration: none;
        .arrow {
          @include arrowActiveState();
        }
      }
    }
    .home-page-news-intro-headline {
      font-size: 110px;
      font-weight: 600;
      line-height: 1;
      @media (min-width: $vp3) and (max-width: $vp4) {
        font-size: 100px;
      }
      @media (max-width: $vp3 - 1px) {
        font-size: 72px;
      }
      @media (max-width: $vp2 + 100) {
        font-size: 32px;
      }
      @media (max-width: $vp2) {
        font-size: 28px;
      }
    }
    .home-page-news-intro-text {
      font-size: 32px;
      line-height: 1;
      @media (min-width: $vp3) and (max-width: $vp4) {
        font-size: 24px;
      }
      @media (max-width: $vp3 - 1px) {
        font-size: 18px;
      }
      @media (max-width: $vp2) {
        font-size: 14px;
      }
      .arrow {
        fill: #FFF;
        height: 10px;
        width: 10px;
        @include arrowTransition();
      }
    }
  }
}

// Landing Page Header

.page-title-container.hero-image-with-title {
  .landing-page-title {
    line-height: 92.5%;
    max-width: 60%;
    background-color: rgb(0,0,0,0.5);
    padding: 30px;
    word-wrap: normal;

    @media (max-width: $vp4) {
      line-height: 1.2;
      padding: 0 20px;
      width: 100%;
    }

    @media (min-width: $vp3) and (max-width: $vp4) {
      font-size: 100px;
    }

    @media (max-width: $vp3 - 1px) {
      font-size: 72px;
    }

    @media (max-width: $vp2) {
      font-size: 40px;
    }
  }
}

#video-wrapper {
  background: $smoke;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
}
iframe {
  width: 100vw;
  height: 56.25vw; // This is for video with 16:9 aspect ratio ( (9/16)*100 = 56.25 )
  position: relative;
  margin-bottom: -60px;
}

.pause-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  cursor: pointer;
}
.pause-button {
  svg {
    height: 5em;
  }
}
</style>
