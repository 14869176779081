<template>

<div class="component">
  <section role="region" class="highlight" :aria-label="component.title">
    <div class="ms-row zero-margin">
      <div class="l-col-1-2">
        <div class="image-cell">
          <img class="image object-fit-cover"
          v-lazy="component.image.url"
          :alt="component.image.description">
        </div>
      </div>
      <div class="l-col-1-2 highlight-vertical-center">
        <div class="content-cell">
          <h2 class="highlight-title" v-html="component.title"></h2>

          <div v-if="component.acf_fc_layout === 'highlight'"
          class="highlight-text"
          v-html="component.text">
          </div>

          <template v-else-if="component.acf_fc_layout === 'quote_highlight'">
            <blockquote class="highlight-quote" v-html="component.text">
            </blockquote>
            <p class="highlight-attribution">—<cite v-html="component.attribution"></cite></p>
          </template>

          <div>
            <c-link class="button"
            v-bind="processUrl(component.link_url)"
            v-html="component.link_text">
            </c-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

</template>

<script>

export default {
  name: 'Highlight',
  props: ['processUrl', 'component'],
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/highlight.scss";

</style>
