<template>

  <footer class="footer" v-show="apiAcf">
    <div class="footer-content">
      <div class="ms-grid">
        <div class="ms-row">
          <div class="s-col-1-2 m-col-1-4 l-col-3-12 footer-brand-address">
            <FooterCircleMark class="footer-brand"
            alt="University of Illinois Chicago"
            aria-hidden="true"/>
            <div class="footer-address" v-if="apiAcf">
              <div v-html="apiFooter.address"></div>
              <div>{{apiFooter.city}}, {{apiFooter.state}} {{apiFooter.zip}}</div>
              <div class="phone" v-html="apiFooter.phone"></div>
            </div>
          </div>

          <template v-if="apiMenus">
            <div v-for="({menu, title, pos}, index) in footerMenus"
            :key="index"
            role="navigation"
            class="s-col-1-2 m-col-1-4 l-col-2-12"
            :class="pos"
            :aria-label="title">
              <h2 class="footer-list-title" v-html="title"></h2>
              <ul class="footer-list">
                <li
                class="footer-list-item"
                v-for="item in processMenuItems(apiMenus[menu].items)"
                :key="item.object_id">
                  <c-link class="footer-list-link"
                  v-bind="processUrl(item.url)"
                  v-html="item.title">
                  </c-link>
                </li>
              </ul>
            </div>
          </template>

          <div class="l-col-3-12">
            <div class="ms-row">
              <div role="navigation"
              class="s-col-1-2 l-col-1-1"
              aria-label="Calls to Action"
              v-if="apiMenus">
                <ul class="footer-button-list">
                  <li
                  class="footer-button-item"
                  v-for="item in processMenuItems(apiMenus[1].items)"
                  :key="item.object_id">
                    <c-link class="footer-button"
                    v-bind="processUrl(item.url)"
                    role="button"
                    v-html="item.title">
                    </c-link>
                  </li>
                </ul>
              </div>
              <div class="s-col-1-2 l-col-1-1 footer-social-icons-cell">
                <ul class="social-icons-list" v-if="apiAcf">
                    <li v-for="(item, index) in socialLinksAvailable" :key="index">
                      <a :href="apiFooter[item]">
                        <span>{{ item }}</span>
                        <IconSVG v-bind:is="IconSVG(item)"/>
                      </a>
                    </li>
                </ul>
                <div class="cookie-banner">
                  <div id="footer-bottom">
                    <!-- OneTrust Cookies Settings button start -->
                    <button id="ot-sdk-btn" class="ot-sdk-show-settings" type="button">Cookie Settings</button>
                    <!-- OneTrust Cookies Settings button end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-legal">
      <div class="ms-grid">
        <div class="ms-row" v-if="apiAcf">
          <div class="l-col-1-2">
            &copy; {{ new Date().getFullYear() }} {{apiFooter.copyright_text}}
            <span class="pipe-separator">|</span>
            <c-link v-bind="processUrl(apiFooter.fine_print_link_url)">
              {{apiFooter.fine_print_link_text}}
            </c-link>
          </div>
          <div class="l-col-1-2 campuses">
            <template v-for="(item, index) in apiFooter.campus_links">
              <c-link v-bind="processUrl(item.link_url)" :key="index">{{item.link_text}}</c-link>
              <span :key="`seperator ${index}`"
              v-if="index != apiFooter.campus_links.length-1"
              class="pipe-separator">|</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>

// Mixins
import processSVG from '@/mixins/processSVG';
import processUrl from '@/mixins/processUrl';
import processMenuItems from '@/mixins/processMenuItems';

// SVG
import FooterCircleMark from '@/components/ui-components/svg/logo-footer-circle-mark.svg';
import FacebookSVG from '@/components/ui-components/svg/icon-facebook.svg';
import TwitterSVG from '@/components/ui-components/svg/icon-xsocial.svg';
import InstagramSVG from '@/components/ui-components/svg/icon-instagram.svg';
import FlickrSVG from '@/components/ui-components/svg/icon-flickr.svg';
import YoutubeSVG from '@/components/ui-components/svg/icon-youtube.svg';
import LinkedinSVG from '@/components/ui-components/svg/icon-linkedin.svg';
import TumblrSVG from '@/components/ui-components/svg/icon-tumblr.svg';
import PinterestSVG from '@/components/ui-components/svg/icon-pinterest.svg';
import GoogleplusSVG from '@/components/ui-components/svg/icon-googleplus.svg';
import TiktokSVG from '@/components/ui-components/svg/icon-tiktok.svg';
import YelpSVG from '@/components/ui-components/svg/icon-yelp.svg';
import SkypeSVG from '@/components/ui-components/svg/icon-skype.svg';
import SnapchatSVG from '@/components/ui-components/svg/icon-snapchat.svg';
import MoreSVG from '@/components/ui-components/svg/icon-getmore.svg';

// IMG
export default {
  name: 'Footer',
  components: {
    FooterCircleMark,
    FacebookSVG,
    TwitterSVG,
    InstagramSVG,
    FlickrSVG,
    YoutubeSVG,
    LinkedinSVG,
    TumblrSVG,
    PinterestSVG,
    GoogleplusSVG,
    TiktokSVG,
    YelpSVG,
    SkypeSVG,
    SnapchatSVG,
    MoreSVG,
  },
  data() {
    return {
      socialLinks: ['facebook', 'twitter', 'instagram', 'flickr', 'youtube', 'linkedin', 'tumblr', 'pinterest', 'googleplus', 'tiktok', 'yelp', 'skype', 'snapchat', 'more'],
      footerMenus: [{ menu: 4, title: 'Tools', pos: 'l-col-12-push-4' }, { menu: 3, title: 'Resources', pos: '' }, { menu: 2, title: 'Quick Links', pos: 'l-col-12-pull-4' }],
    };
  },
  mixins: [processMenuItems, processUrl, processSVG],
  props: ['apiAcf'],
  computed: {
    apiMenus() {
      return this.$store.state.menus;
    },
    apiFooter() {
      return this.apiAcf.uic_footer;
    },
    socialLinksAvailable() {
      const links = this.socialLinks;
      const footer = this.apiFooter;
      const finalArray = [];
      links.forEach((item) => {
        if ((item in footer) && (footer[item] !== '')) {
          finalArray.push(item);
        }
      });
      return finalArray;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

// begin mixins
@mixin footerButtonList {
  list-style: none inside;
  margin-bottom: 12px;
  min-width: 20%;
  @media (max-width: $vp4) {
    width: 134px;
    padding-right: 0px;
  }
  @media (max-width: $vp3) {
  }
  @media (max-width: $vp2) {
    width: 100%;
    margin-top: 20px;
  }
  @media (max-width: $vp1) {
    width: 100%;
  }
}
@mixin footerButtonItem {
  margin-bottom: 15px;
  @media (max-width: $vp4) {
    margin-bottom: 10px;
  }
  @media (max-width: $vp3) {
    margin-bottom: 5px;
  }

}
@mixin footerButton {
  color: $red;
  display: block;
  font-size: 19px;
  font-weight: 500;
  line-height: 32px;
  height: 32px;
  padding: 0;
  text-decoration: none;
  transition: color 0.4s;
  text-transform: uppercase;
  &:hover,
  &:visited {
    color: $red;
    text-decoration: none;
  }
  &:hover,
  &:focus {
    color: $red;
    outline: none;
    text-decoration: underline;
  }
  @media (max-width: $vp4) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  @media (max-width: $vp3) {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0px;
  }
  @media (max-width: $vp2) {
    font-size: 15px;
    line-height: 18px;
  }
}
@mixin footerText() {
  color: #000000;
  font-size: 14px;
}
@mixin legal {
  background: $navy;
  color: white;
  font-size: 13px;
  line-height: 42px;
  height: 42px;
  a,
  a:hover,
  a:visited {
    color: white;
    text-decoration: none;
  }

  a:hover,
  a:focus {
    outline: none;
    text-decoration: underline;
  }
  .pipe-separator {
    margin: 0em 0.5em;
  }
  .campuses {
    text-align: right;
  }
}
// end mixins

// begin classes
.footer {
  @include footerText();
  .footer-content {
    background: $gray no-repeat top center;
    background-size: cover;
    padding: 35px 0px 35px 0;
  }
  .footer-brand { // circle logo
    margin-bottom: 15px;
    width: 180px;
    @media (max-width: $vp4) {
      width: 120px;
    }
    @media (max-width: $vp3) {
      width: 90px;
    }
    @media (max-width: $vp2) {
      width: 120px;
    }
  }
  .footer-brand-address {
    margin-bottom: 20px;
    @media (min-width: $vp4) {
      margin-bottom: 0px;
    }
  }
  .footer-address {
    font-size: 15px;
    @media (min-width: $vp4) {
      bottom: 0px;
      left: 1vw;
      position: relative;
      width: 100%;
      color: $charcoal;
    }
    @media (max-width: $vp3) {
      font-size: 8px;
    }
    @media (max-width: $vp2) {
      font-size: 15px;
    }
    @media (max-width: $vp1) {
      font-size: 15px;
      margin-bottom: 25px;
    }
    .phone {
      margin-top: 10px;
    }
    a[href^="tel"] {
      &,
      &:hover,
      &:visited {
        color: #000000;
        text-decoration: none;
      }
    }
  }
  .footer-list-title {
    color: $red;
    font-size: 19px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
    @media (max-width: $vp4) {
      font-size: 16px;
    }
    @media (max-width: $vp3) {
      font-size: 11px;
    }
    @media (max-width: $vp2) {
      font-size: 15px;
    }
  }
  .footer-list {
    list-style: none inside;
    @media (max-width: $vp4) {
      @include clearfix();
      margin-bottom: 30px;
    }
  }
  .footer-list-item {
    margin-bottom: 10px;
    @media (max-width: $vp3) {
      line-height: 15px;
      margin-bottom: 15px;
    }
    @media (max-width: $vp2) {
      margin-bottom: 20px;
      line-height: 135%;
    }

    .footer-list-link,
    .footer-list-link:visited {
      color: #000000;
      font-size: 14px;
      text-decoration: none;
      @media (max-width: $vp3) {
        font-size: 12px;
      }
      @media (max-width: $vp2) {
        font-size: 15px;
        margin-bottom: 14px;
      }
    }
    .footer-list-link:hover,
    .footer-list-link:focus {
      outline: none;
      text-decoration: underline;
    }
  }
  .footer-list-item:last-child {
    margin-bottom: 0px;
  }
  .footer-button-list {
    @include footerButtonList();
  }
  .footer-button-item {
    @include footerButtonItem();
  }
  .footer-button {
    @include footerButton();
    background: none;
  }

  .social-icons-list {
    // min-height: 65px;
    list-style: none inside;
    overflow: hidden;
    @media (max-width: vp4) {
      margin-top: 30px;
    }
    @media (max-width: $vp3) {
      margin-top: 30px;
      min-width: 140px;
      max-width: 300px;
    }

    $size: 30px;
    a,
    a:hover,
    a:visited {
      text-decoration: none;
    }

    svg { // social media icons
      fill: #949494;
      height: $size;
      transition: fill 0.4s;
      width: $size;
      @media (max-width: $vp4) {
        width: ($size * .8);
        height: ($size * .8);
      }
      @media (max-width: $vp2) {
        width: ($size * 1.2);
        height: ($size * 1.2);
      }
      @media (max-width: $vp1) {
        width: ($size * 1.5);
        height: ($size * 1.5);
      }
    }
    span {
      @include screenreaderOnly();
    }

    li {
      border-radius: 50%;
      display: block;
      float: left;
      height: $size;
      margin: 0px 10px 10px 0px;
      width: $size;&:nth-of-type(5n+1) {
        // clear: left;
      }
      @media (max-width: $vp2) {
        width: ($size * 1.2);
        height: ($size * 1.2);
      }
      @media (max-width: $vp1) {
        width: ($size * 1.5);
        height: ($size * 1.5);
      }
    }

    a:hover,
    a:focus {
      outline: none;
      svg {
        fill: $red;  // ICON COLOR FILL.  this applies to the last element / top layer element in the svg
      }
    }
  }
  .footer-legal {
    @include legal();
    @media (max-width: $vp4) {
      line-height: 1.5;
      height: auto;
      padding: 1em 0;
    }
    @media (max-width: $vp3) {
      margin-top: 0px;
    }
    .campuses {
      @media (max-width: $vp4) {
        text-align: right;
      }
      @media (max-width: $vp2) {
        text-align: left;
      }
    }
  }
  .l-col-1-1 { // calls to action
    width: 300px;
    @media (max-width: $vp4) {
      width: 50%;
      font-size: 10px;
    }
    @media (max-width: $vp3) {
      font-size: 7px;
      width: 50%;
    }
    @media (max-width: $vp2) {
      font-size: 14px;
      width: 100%;
    }
  }
  .l-col-1-2 { // legal and campus boxes
    width: 50%;
    @media (max-width: $vp4) {
      font-size: 10px;
    }
    @media (max-width: $vp3) {
      width: 50%;
      font-size: 7px;
    }
    @media (max-width: $vp2) {
      width: 100%;
      font-size: 14px;
    }
  }
  .l-col-3-12 { // this is the column for call to action menu plus social media icons
    @media (max-width: $vp4) {
      max-width: 20%;
    }
    @media (max-width: $vp3) {
      max-width: 20%;
    }
    @media (max-width: $vp2) {
      font-size: 14px;
      max-width: 100%;
    }
    @media (max-width: $vp1) {
      max-width: 100%;
    }
  }
  .s-col-1-2 {
    @media (max-width: $vp4) {
      width: 20%;
      margin-bottom: 20px;
    }
    @media (max-width: $vp3) {
      width: 20%;
      // max-width: 20%;
      margin-bottom: 15px;
    }
    @media (max-width: $vp2) {
      width: 50%;
      max-width: 50%;
      margin-bottom: 10px;
    }
    @media (max-width: $vp1) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 5px;
    }
  }
}

// begin -- not sure how to nest this above, come back to this later
.no-flexbox {
  @media (max-width: $vp4) {
    .footer-social-icons-cell {
      bottom: -30px;
    }.footer-address {
      bottom: -50px;
    }
  }
  @media (max-width: $vp3) {
    .footer-social-icons-cell {
      margin: 0;
    }
  }

}
.flexbox {
  .footer-social-icons-cell {
    bottom: 0px;
    left: 0px;
    position: relative;
    width: 100%;
    margin: 0;
    @media (max-width: $vp4) {
      bottom: 0px;
      left: 0px;
      position: relative;
      width: 100%;
      margin: 0;
    }
  }
}
// end -- not sure how to nest this above, come back to this later

// Cookie Button
#footer-bottom {
  width: 152px;
}

// end classes

</style>
