<template>
  <div class="spotlight-block" v-if="this.getAcf && assignedSpotlightBlocks">

    <template v-for="(block, index) in assignedSpotlightBlocks">

      <div class="single-block ms-row" v-if="posts[index].ID" :key="index">
        <div class="image-container m-col-1-2"
        :class="{'m-col-2-push-1': !(index % 2)}"
        aria-hidden="true">
          <div v-if="(posts[index].acf.image_presentation_type == 'Square') || index == 0"
          class="image-bg"
          v-lazy:background-image="posts[index].acf.image.url"></div>
          <img v-if="(posts[index].acf.image_presentation_type == 'Circle') && index != 0"
          class="image"
          :class="{'circle': index != 0}"
          v-lazy="posts[index].acf.image.url"
          alt=""/>
        </div>
        <div class="m-col-1-2"
        :class="{'m-col-2-pull-1 message-on-left': !(index % 2), 'message-on-right': index % 2}">
          <div class="message-cell" :class="{'quote': index != 0}">
            <div class="text">
              <h2 class="promo-header" v-html="posts[index].acf.headline"></h2>
              <IconQuote v-if="index != 0"/>
              <div v-html="posts[index].acf.text"></div>
              <div class="credential"
              v-if="
                (index != 0)
                && (posts[index].acf.first_and_last_name
                || posts[index].acf.major
                || posts[index].acf.year)
              ">
                <span v-html="posts[index].acf.first_and_last_name"></span>
                <span v-if="posts[index].acf.major || posts[index].acf.year">
                  &ensp;|&ensp;
                </span>
                <span v-if="posts[index].acf.year"
                  v-html="`'${posts[index].acf.year},&nbsp;`"></span>
                <span v-html="posts[index].acf.major"></span>
              </div>
            </div>
            <ul class="message-button-list">
              <li class="message-button-list-item">
                <c-link
                :class="{'navy-button': !(index % 2), 'white-button': index % 2}"
                v-bind="processUrl(posts[index].acf.link_url)"
                role="button"
                v-html="posts[index].acf.link_text">
                </c-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </template>

  </div>
</template>

<script>

// Mixins
import processUrl from '@/mixins/processUrl';

// SVG
import IconQuote from '@/components/ui-components/svg/icon-quote.svg';

export default {

  name: 'SpotlightBlock',
  mixins: [processUrl],
  components: {
    IconQuote,
  },
  data() {
    return {
      posts: [{}, {}, {}],
    };
  },
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getPost() {
      return this.$store.state.postSpotlight;
    },
    assignedSpotlightBlocks() {
      const spotlights = {};
      spotlights[0] = this.getAcf.uic_home.position_1;
      spotlights[1] = this.getAcf.uic_home.position_2;
      spotlights[2] = this.getAcf.uic_home.position_3;
      Object.keys(spotlights).forEach((i) => {
        if (spotlights[i].ID && spotlights[i].ID != null) {
          this.$store.dispatch('getPost', { id: spotlights[i].ID, type: 'postSpotlight' });
        } else {
          spotlights[i] = null;
        }
      });
      return spotlights;
    },
  },
  watch: {
    getPost(newVal) {
      if (newVal !== null) {
        Object.keys(this.assignedSpotlightBlocks).forEach((i) => {
          if (this.assignedSpotlightBlocks[i].ID === newVal.ID) {
            // this.posts[i] = newVal; // this is for when updating to Vue3; V3 doesnt use $set()
            this.$set(this.posts, i, newVal);
          }
        });
        this.$store.commit('resetPost', { type: 'postSpotlight' });
      }
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.spotlight-block {
  .single-block {
    background-color: #FFF;
    @media (max-width: $vp4) {
      padding: 0 20px;
    }
  }
  .single-block:nth-child(2),
  .single-block:nth-child(3) {
    .message-cell {
      .promo-header {
        color: #FFF;
        font-size: 22px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 36px;
        letter-spacing: 3px;
        @media (min-width: $vp2) and (max-width: $vp3) {
          font-size: 36px;
        }
      }
      .text {
        color: #FFF;
        font-weight: 500;
        line-height: 1.2;
        font-size: 36px;
        div ::v-deep p:first-child {
          margin-bottom: 0.7em;
          @media (max-width: $vp3) {
            font-size: 28px;
          }
        }
        .credential {
          word-break: break-word;
          font-size: 22px;
          margin-bottom: 1.8em;
          span:nth-child(1) {
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }
      }
      .message-button-list {
        @media (min-width: $vp2) and (max-width: $vp3) {
          margin-left: 70px;
        }
      }
      .icon-quote {
        height: 50px;
        position: absolute;
        transform: translate(-65px, -10px);
      }
      &.quote {
        .text,
        .message-button-list-item {
          @media (max-width: $vp5) {
            padding-left: 70px;
          }
        }
      }
    }
  }
  .single-block:nth-child(2) {
    background-color: $red;
    .icon-quote {
      fill: #000;
      mix-blend-mode: overlay;
    }
  }
  .single-block:nth-child(3) {
    .message-cell {
      .promo-header {
        color: $navy;
      }
      .text {
        color: $navy;
      }
      .icon-quote {
        fill: #e7e7e7;
      }
    }
  }
  .message-cell {
    // padding: $smallGutter $smallGutter $smallGutter * 2;
    padding: 20px;

    @media (min-width: $vp2) and (max-width: $vp3) {
      padding: 35px 70px;
    }

    @media (min-width: $vp3) {
      // max-height: 584px;
      // padding: 14px $mediumGutter;
    }

    @media (min-width: $vp4) {
      padding-top: 65px;
      padding-bottom: 50px;
      width: $siteContentWidth / 2;
    }
    @media (min-width: $vp4h) {
      width: $siteContentWidthExtended / 2;
    }
    ::v-deep.text {
      @include bodyText();
      color: $gray-text;
      font-size: 25px;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 30px;
      @include navyHyperlinks();

      @media (min-width: $vp3) {
        margin-bottom: 20px;
      }
    }
  }
  .message-on-left,
  .message-on-right {
    @media (min-width: $vp3) {
      display: flex;
      align-items: center;
    }
  }

  .message-on-left {
    justify-content: flex-end;
    -ms-flex-pack: end;
    .message-cell {
      .promo-header {
        font-size: 40px;
        color: $navy;
        font-weight: 600;
        line-height: 96%;
        margin-bottom: 19px;

        @media (min-width: $vp3) and (max-width: $vp4) {
          font-size: 48px; // fix
        }

        @media (max-width: $vp3) {
          font-size: 36px; // fix
        }
      }
      // @media (min-width: $siteContentWidth + 60px) {
      @media (min-width: $vp3) {
        float: right;
        padding-right: 50px;
        padding-left: 0px;
      }
    }
  }
  .message-on-right {
    .message-cell {
      // @media (min-width: $siteContentWidth + 60px) {
      //   padding-left: 50px;
      //   padding-right: 0px;
      // }
    }
  }

  .message-button-list {
    list-style: none inside;
    .message-button-list-item {
      display: block;
      margin-right: 8px;
      margin-bottom: 8px;

      @media (min-width: $vp3) {
        float: left;
      }
    }
    .navy-button,
    .white-button {
      margin-top: 5px;
    }
    .navy-button {
      @include button(#FFF, $navy);
      &:hover,
      &:focus {
        background-color: $red;
      }
    }
    .white-button {
      @include button($gray-text, #FFF);
      &:hover,
      &:focus {
        background-color: #b2000a;
        color: #FFF;
      }
    }
  }
  .image-container {
    @media (min-width: $vp2) and (max-width: $vp3) {
      margin: 65px 70px 0;
    }
    .image-bg {
      background: transparent no-repeat center center;
      background-size: cover;
      height: 224px;
      @media (min-width: $vp2) and (max-width: $vp3) {
        height: 562px;
      }
      @media (min-width: $vp3) {
        height: 584px;
      }
    }
    .image {
      max-width: 100%;
      object-fit: cover;

      &.circle {
        clip-path: circle(37.5% at 50% 50%);
        @media (min-width: $vp2) and (max-width: $vp3) {
          clip-path: circle(45% at 50% 50%);
        }
      }
    }
  }

}

</style>
