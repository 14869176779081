<template>

<div class="component">
  <div class="landing-page-intro">
    <div class="ms-row">
      <div class="col-1-1">
        <div class="interior-section-header" v-html="pageContent.acf.subhead">
        </div>
      </div>
    </div>
    <div class="ms-row">
      <div class="col-1-1">
        <div class="intro-text" v-html="pageContent.acf.text">
        </div>
      </div>
    </div>
    <div class="ms-row link-block-group" v-if="pageContent.acf.link_group.length > 0">
      <c-link class="s-col-1-2 l-col-1-3 link-block-anchor"
      v-for="(item, index) in pageContent.acf.link_group"
      :key="index"
      v-bind="processUrl(item.link_url)">
        <article class="link-block">
          <h1 class="subhead">
            {{item.title}}
            <ArrowSVG/>
          </h1>
          <div class="text" v-html="item.link_text"></div>
        </article>
      </c-link>
    </div>
  </div>
</div>

</template>

<script>

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

export default {
  name: 'LandingPageIntro',
  props: ['pageContent', 'processUrl'],
  components: {
    ArrowSVG,
  },
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/link-block-group.scss";

// Landing Page Intro

.landing-page-intro {
  ::v-deep.interior-section-header {
    margin-bottom: 15px;
    @include navyHyperlinks();
  }
  ::v-deep.intro-text {
    @include bodyText();
    @include navyHyperlinks();
  }
}

</style>
