<template>
  <div class="ms-grid">
    <div class="key-message-block" v-if="this.getAcf">

      <template v-for="(r, index) in countAssignedMessageBlocks">

        <div class="key-message-single" v-if="posts[index].ID" :key="index">
          <div class="image2020" v-lazy:background-image="posts[index].acf.image.url"></div>
          <h2 class="promo-header" v-html="posts[index].acf.headline"></h2>
          <div class="text">
            <p v-html="posts[index].acf.text"></p>
          </div>
          <ul class="message-button-list">
            <li class="message-button-list-item"
            v-for="(link, index) in posts[index].acf.links"
            :key="index">
              <c-link
              v-bind="processUrl(link.link_url)">
              {{ link.link_text }}
              <ArrowSVG/>
              </c-link>
            </li>
          </ul>
        </div>

      </template>

    </div>
  </div>
</template>

<script>

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

// Mixins
import processUrl from '@/mixins/processUrl';

export default {

  name: 'KeyMessageBlock',
  mixins: [processUrl],
  components: {
    ArrowSVG,
  },
  data() {
    return {
      posts: [{}, {}, {}],
    }
  },
  computed: {
    getAcf() {
      return this.$store.state.acf;
    },
    getPost() {
      return this.$store.state.postKeyMessage;
    },
    assignedMessageBlocks() {
      return this.getAcf.uic_home.message_blocks;
    },
    countAssignedMessageBlocks() {
      let number = 0;
      Object.keys(this.assignedMessageBlocks).forEach((i) => {
        if (this.assignedMessageBlocks[i].message_block.ID
        && this.assignedMessageBlocks[i].message_block.ID != null) {
          number += 1;
          this.$store.dispatch('getPost', { id: this.assignedMessageBlocks[i].message_block.ID, type: 'postKeyMessage' });
        }
      });
      const array = Array.from(Array(number).keys());
      return array;
    },
  },
  watch: {
    getPost(newVal, oldVal) {
      if (newVal !== null) {
        Object.keys(this.assignedMessageBlocks).forEach((i) => {
          if (this.assignedMessageBlocks[i].message_block.ID === newVal.ID) {
            // this.posts[i] = newVal; // this is for when updating to Vue3; V3 doesnt use $set()
            this.$set(this.posts, i, newVal);
            this.$store.commit('resetPost', { type: 'postToday' });
          }
        });
      }
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "@/scss/shared/wysiwyg.scss";

.key-message-block{
  display: flex;
  margin: 0 auto;
  max-width: 1160px;
  margin-top: 110px;
  margin-bottom: 60px;
  justify-content: space-between;
    @media (max-width: $vp4) {
      width: 100%;
    }
    @media (max-width: $vp3) {
      display: block;
      width: 100%;
    }
    @media (max-width: $vp2) {
      display: block;
      width: 100%;
    }
}

.key-message-single {
  background: #ffffff;
  width: 30%;
  box-shadow: 0px 6px 17px 0px rgba(0,0,0, 0.15);
  ::v-deep.text{
    @include bodyText();
    font-size: 16px;
    margin: 0px 40px 20px 40px;
      @media (max-width: $vp4) {
        font-size: 12px;
        margin: 0px 20px 10px 20px;
      }
      @media (max-width: $vp3) {
        font-size: 16px;
      }
      @media (max-width: $vp2) {
        font-size: 14px;
      }
      p {
        margin-bottom: 10px;
      }
    @include navyHyperlinks();
  }
    @media (max-width: $vp3) {
      width: 100%;
      max-width: 768px;
      margin: 0px 0px 40px 0px;
    }
}

.key-message-block .image2020 {
  border-top: 20px solid $red;
  background: transparent no-repeat center center;
  background-size: cover;
  height: 210px;
  overflow: hidden;
    @media (max-width: $vp4) {
      border-top: 15px solid $red;
      height: calc(100vw / 5);
    }
    @media (max-width: $vp3) {
      border-top: 20px solid $red;
      height: calc(100vw / 1.8);
    }
}

h2.promo-header {
  font-size: 32px;
  font-weight: 600;
  line-height: 105%;
  margin: 26px 40px 15px 40px;
  color: $navy;
    @media (max-width: $vp4) {
      font-size: 24px;
      margin: 13px 20px 8px 20px;
   }
    @media (max-width: $vp3) {
      font-size: 30px;
      font-weight: 500;
      margin: 20px 20px 8px 20px;
   }
    @media (max-width: $vp2) {
      font-size: 22px;
   }
}

.promo-header {
  margin: 20px 0;
}

ul.message-button-list {
  margin: 0 40px;
  margin-bottom: 40px;
  list-style: none;
    @media (max-width: $vp4) {
      margin: 0 20px;
      margin-bottom: 20px;
    }
    @media (max-width: $vp3) {
      padding-bottom: 30px;
      margin-bottom: 0px;
    }
}

ul.message-button-list .message-button-list-item {
  margin-bottom: 10px;
}

ul.message-button-list .message-button-list-item a {
  @include linkWithArrow($red);
  .arrow {
    margin-left: 5px;
  }
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 500;
    @media (max-width: $vp4) {
      letter-spacing: 2.0px;
      font-size: 14px;
    }
    @media (max-width: $vp3) {
      letter-spacing: 1.5px;
      font-size: 18px;
    }
    @media (max-width: $vp2) {
      font-size: 14px;
    }
}

</style>
