<template>

  <div class="header-menu-bar" :class="{ 'homepage-header-navigation': pageType.homePage }">
    <nav class="navigation-eyebrow" aria-label="Eyebrow">
      <div class="navigation-eyebrow-inner" v-if="this.apiMenus">
        <ul class="eyebrow-list"
        :style="menuCount">
          <li
          class="eyebrow-list-item"
          v-for="item in processMenuItems(apiMenus[0].items)"
          :key="item.object_id"
          :class="{ 'open': menuOpen }"
          ref="eyebrowLi">
            <c-link class="eyebrow-list-anchor"
            v-bind="processUrl(item.url)">
              {{ item.title }}
            </c-link>
          </li>
          <li class="eyebrow-list-item search-button-item">
            <button @click="searchToggle"
            class="search-button"
            :class="{ 'active': searchOpen }"
            type="button">
              <SearchGlassSVG/>
            </button>
          </li>
        </ul>
        <div class="search-input-container" :class="{ 'open': searchOpen }">
          <form method="get" action="/search" role="search">
            <input
            name="q"
            class="search-input"
            placeholder="Search..."
            :disabled="!searchOpen"
            :tabindex="[searchOpen ? 0 : -1]"
            @blur="searchOpen = false"
            aria-label="Search"
            type="text"
            ref="search">
            <input
            type="hidden"
            name="global_search"
            value="true">
            <input class="submit-button" name="search" value="Submit" type="submit">
          </form>
        </div>
      </div>
    </nav>
    <nav class="navigation-primary"
    ref="navigationPrimary"
    aria-label="Primary">
      <div class="navigation-primary-inner">

        <div class="circle-logo-container"
        :class="{
          'full-logo': !pageType.innerPage,
          'misc-page': $route.name == '404' || $route.name == 'Search'
        }">
          <router-link :to="{ name: 'Home'}">
            <CircleLogoSVG/>
            <LargeBrandMarkSVG v-if="!pageType.innerPage"/>
            <span>University of Illinois Chicago</span>
          </router-link>
        </div>

        <ul class="primary-nav-list"
        :class="{ 'open': menuOpen }"
        ref="primaryNavUl"
        v-if="this.apiMenus"
        v-click-outside="focusOutMenu">

          <li class="menu-toggle-item">
            <button class="menu-toggle"
            :class="{ 'active': menuOpen }"
            @click="menuToggle"
            type="button"
            role="button"
            tabindex="-1">
              <span class="menu-toggle-icon"></span>
              <span>Expand menu</span>
            </button>
          </li>

          <li class="menu-label-item">
            <span class="menu-label">Menu</span>
          </li>

          <c-link tag="li"
          class="primary-list-item"
          @mouseenter.native="menuItemToggle(true, $event)"
          @mouseleave.native="menuItemToggle(false, $event)"
          v-bind="processUrl(item.parent.url)"
          active-class="primary-list-active-item"
          v-for="item in mainMenu"
          :key="item.parent.id">
            <a class="primary-list-anchor" v-html="item.parent.title"></a>
            <ul class="primary-second-tier-list"
            v-if="item.children.length > 0">
              <li
              class="primary-second-tier-list-item"
              v-for="subItem in item.children"
              :key="subItem.id">
                <c-link
                class="primary-second-tier-list-anchor"
                v-bind="processUrl(subItem.url)"
                v-html="subItem.title">
                </c-link>
              </li>
            </ul>
            <div class="expander"
            @click="menuToggleTier2"
            @keyup.enter="menuToggleTier2"
            role="button"
            tabindex="0"
            :aria-label="'expand ' + item.parent.title"
            v-if="item.children.length > 0"></div>
          </c-link>

        </ul>

      </div>
    </nav>
  </div>

</template>

<script>

// Mixins
import processMenuItems from '@/mixins/processMenuItems';
import mainMenu from '@/mixins/mainMenu';
import processUrl from '@/mixins/processUrl';

// SVG
import LargeBrandMarkSVG from '@/components/ui-components/svg/uic-logo-full-color-rgb.svg';
import CircleLogoSVG from '@/components/ui-components/svg/logo-circle.svg';
import SearchGlassSVG from '@/components/ui-components/svg/icon-search-glass.svg';

export default {
  name: 'Navigation',
  components: {
    LargeBrandMarkSVG,
    CircleLogoSVG,
    SearchGlassSVG,
  },
  mixins: [
    processMenuItems,
    mainMenu,
    processUrl,
  ],
  props: ['pageType'],
  data() {
    return {
      searchOpen: false,
      menuOpen: false,
    };
  },
  computed: {
    apiMenus() {
      return this.$store.state.menus;
    },
    menuCount() {
      // calculated delay for eyebrow menu animation in hamburger menu.
      return `--menu-count: calc(${this.mainMenu.length + 3} * 0.08s);`;
    },
  },
  methods: {
    hamburgerHeight() {
      this.$nextTick(() => {
        const mainMenuHeight = this.$refs.primaryNavUl.offsetHeight;
        const eyebrowItems = this.$refs.eyebrowLi;
        Object.keys(eyebrowItems).forEach((i) => {
          const calcOffset = (mainMenuHeight - 1) + (i * 44);
          eyebrowItems[i].style.top = `${calcOffset}px`;
        });
      });
    },
    searchToggle() {
      this.searchOpen = !this.searchOpen;
      if (this.searchOpen === true) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
      }
    },
    menuToggle() {
      this.menuOpen = !this.menuOpen;
      this.hamburgerHeight();
    },
    menuItemToggle(bool, event) {
      if (bool === true) {
        event.target.classList.add('open');
      } else if (bool === false) {
        event.target.classList.remove('open');
      }
    },
    menuToggleTier2(event) {
      this.hamburgerHeight();
      event.target.classList.toggle('open');
      const menu = event.target.parentNode.getElementsByTagName('ul')[0];
      const startHeight = menu.getBoundingClientRect().height;
      menu.classList.toggle('open');
      const endHeight = menu.getBoundingClientRect().height;
      menu.classList.toggle('open');
      TweenLite.fromTo(menu, 0.0, { height: startHeight }, {
        height: endHeight,
        onStart() {
          menu.style.display = 'block';
        },
        onComplete() {
          menu.style.display = '';
          menu.style.height = '';
          menu.classList.toggle('open');
        },
      });
    },
    focusOutMenu() {
      this.menuOpen = false;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.header-menu-bar {
  position: relative;
}

.header--lightgray {
  .header-menu-bar {
    background-color: $lightgray;
  }

  @media (max-width: $vp4) {
    .navigation-primary .primary-nav-list .primary-list-item {
      background-color: $lightgray;
    }
  }.navigation-primary .primary-nav-list .expander,
  .navigation-primary .primary-nav-list .menu-label-item {
    // background-color: darken($colorHex, 8.43%);
    background-color: $red;
  }

  @media (max-width: $vp4) {
    .navigation-primary .primary-nav-list .primary-list-active-item > .primary-list-anchor {
      background-color: darken($lightgray, 8.43%);
    }
  }

  @media (min-width: $vp4) {
    .navigation-eyebrow {

      background-color: $lightgray;
    }
  }

  .navigation-primary .primary-nav-list .primary-second-tier-list {
    border-color: $lightgray;
  }
}

.header-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: -5px;
  filter: blur(5px);
  left: -5px;
  position: absolute;
  right: -5px;
  top: -5px;
}

// Home Page Header

.homepage-header-navigation {
  .navigation-primary {
    .circle-logo-container {
      opacity: 1;
      @media (max-width: $vp4) {
        opacity: 1;
      }
    }
  }
}

// Inner Page

.interior-page-header {
  overflow: visible;
  .header-menu-bar {
    .navigation-primary .circle-logo-container {
      @media (min-width: $vp4) {
        margin-left: 0px;
        opacity: 1;
        z-index: 2;
      }
    }
    .navigation-primary:not(.scrolled-after) .circle-logo-container:not(.misc-page) {
      @media (min-width: $vp4) {
        top: 95px;
      }
    }
    .navigation-eyebrow {
      @media (min-width: $vp4) {
        background-color: $lightgray;
        position: static;
      }
    }
    .navigation-primary {
      background-color: $lightgray;
      width: 100%;
      .menu-toggle {
        z-index: 2;
      }

      @media (min-width: $vp4) {
        height: 60px;
      }
      .primary-list {

        padding-top: 0px;
        z-index: 1;

        @media (min-width: $vp4) {
          height: 60px;
        }
      }
    }
  }
}

// Navigation Eyebrow

.navigation-eyebrow {
  height: 55px;
  @media (max-width: $vp4) {
    height: 60px;
  }
  @media (min-width: $vp4) {
    padding-top: 15px;
    background-color: $blue;
  }
  .navigation-eyebrow-inner {
    @include centerfy();
    position: relative;
    @media (max-width: $vp4) {
      overflow-x: hidden;
    }
  }
  .eyebrow-list {
    display: flex;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    -ms-flex-pack: justify;
    list-style: none inside;
    margin: 0px auto;
    padding: 0px 20px;

    @media (min-width: $vp4) {
      padding: 0px 0px 0px 25.66%;
    }

    @media (max-width: $vp4) {
      width: 260px;
      display: flex;
      flex-wrap: wrap;
      height: 200vh;
    }
  }
  .eyebrow-list-item {
    display: none;
    line-height: 40px;
    height: 40px;
    @media (min-width: $vp4) {
      display: block;
      float: left;
    }
    &:not(.search-button-item):not(.eyebrow-button) .eyebrow-list-anchor {
      @include underlineFromBelow();
      &:before {
        background-color: $red;
        height: 4px;
      }
    }
    @media (max-width: $vp4) {
      &:not(.search-button-item) {
        display: block;
        padding: 0 20px;
        width: 260px;
        background-color: white;
        transform: translateX(260px);
        transition-delay: var(--menu-count);
        transition-property: transform;
        transition-duration: 0.33s;
        position: absolute;
        right: 0;
        z-index: 999999999;
        height: fit-content;
        &.open {
          transform: translateX(0px);
        }
        .eyebrow-list-anchor {
          height: 44px;
        }
      }
    }
  }
  .eyebrow-button {
    align-self: center;
    .eyebrow-list-anchor {
      font-weight: 700;
      padding-left: 34px;
      padding-right: 20px;
      position: relative;
      @include underlineFromBelow();
      &:before {
        background-color: $navy;
        height: 4px;
      }
      &:after {
        content: '+';
        left: 10px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: 20px;
      }
    }
  }
  .eyebrow-list-anchor {
    display: block;
    outline: none;
  }
  .eyebrow-list-anchor,
  .eyebrow-list-anchor:hover,
  .eyebrow-list-anchor:visited {
    color: $navy;
    text-decoration: none;
  }
  .search-button-item {
    display: block;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 40px;
    width: 40px;
    z-index: 1;
    @media (max-width: $vp4) {
      right: 75px;
    }
    @media (min-width: $vp4) {
      height: 40px;
      position: relative;
      top: 0px;
      right: 0px;
      width: auto;
    }
  }
  .search-button {
    background-color: transparent;
    cursor: pointer;
    height: 40px;
    transition: background-color 0.4s;
    width: 40px;
    @media (max-width: $vp4) {
      height: 60px;
      width: 60px;
    }

    span {
      @include screenreaderOnly();
    }
    .search-glass {
      fill: $navy;
      height: 20px;
      margin: 10px;
      transition: fill 0.4s;
      width: 20px;
      @media (max-width: $vp4) {
        height: 30px;
        width: 30px;
        top: -10px;
      }
    }
    &.active,
    &:focus {
      background-color: $red;
      outline: none;
      .search-glass {
        fill: #FFF;
      }
    }
  }
  .search-input-container {
    position: absolute;
    top: 0px;
    height: 60px;
    right: 115px;
    transform: translateY(-140%);
    transition: all 0.4s;
    width: 278px;
    z-index: 3;

    @media (min-width: $vp4) {
      height: 40px;
      right: 40px;
    }
    @media (max-width: 400px) {
      width: 150px;
    }
    &.open {
      transform: translateY(0px);
    }

    form {
      position: relative;
      width: 100%;
      height: 100%;
    }

    input[type="text"] {
      color: #959595;
      height: 100%;
      padding: 0 12px;
      font-size: 18px;
      width: 100%;
    }
    .submit-button {
      @include screenreaderOnly();
    }
  }
}

// Navigation Primary

.navigation-primary {
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;

  @media (min-width: $vp4) {
    pointer-events: auto;
    position: relative;
    padding-bottom: 15px;
  }
  .navigation-primary-inner {
    @include centerfy();
    position: relative;
    min-height: 40px;
  }
  .primary-nav-list {
    @include primaryNavList($vp4);
    @media (min-width: $vp4) {
      .primary-list-item.open .primary-second-tier-list-anchor {
        &:hover,
        &:focus {
          color: $blackish;
        }
      }
      .primary-list-item.open .primary-second-tier-list-anchor.router-link-active {
        background-color: $red;
        color: #FFF;
        &:hover {
          background-color: darken($red, 8.43%);
          color: #FFF;
        }
      }
    }
  }
  .circle-logo-container {
    height: 40px;
    left: 0px;
    opacity: 0;
    pointer-events: auto;
    position: absolute;
    transform: translateY(-100px);
    top: 10px;
    width: 40px;
    z-index: 1;

    span {
      @include screenreaderOnly();
    }

    @media (max-width: $vp4) {
      height: 52px;
      margin-left: $smallGutter;
      opacity: 1;
      transform: translateY(4px);
      top: 0px;
      width: 52px;
    }
    .circle-logo path {
      fill: $red;
      transition: all 0.5s;
    }

    a {
      color: #FFF;
      outline: none;
    }
    .circle-logo {
      transform: rotate(-360deg);
    }
  }
  //remove full-logo in sticky menu
  &.scrolled-before,
  &.scrolled-after {
    .large-brand-mark {
      display: none;
    }
  }
  //change to full logo on home and landing page and mobile (not when stick-nav or inner pages)
  &:not(.scrolled-after):not(.scrolled-before) {
    & .navigation-primary-inner > .circle-logo-container.full-logo {
      height: unset;
      width: 270px;
      opacity: 1;
      .text {
        fill: $navy;
      }
      .circle-mark {
        fill: $red;
      }
      @media (min-width: $vp4) {
        transform: translateY(-40px);
      }
      @media (max-width: 400px) {
        width: 52px;
        .large-brand-mark {
          display: none;
        }
      }
      @media (min-width: 400px) {
        & .circle-logo {
          display: none;
        }
      }
    }
  }

  @media (min-width: $vp4) {
    &.scrolled-after,
    &.scrolled-before {
      background: $lightgray;
      height: 60px;
      position: fixed;
      top: 0px;
      .primary-nav-list {
        padding: 15px 0px 0px 25.66%;
      }
    }
    &.scrolled-after {
      transform: translateY(-60px);
    }
    &.scrolled-after-active {
      transform: translateY(0px);
      transition: all 0.5s;
      .circle-logo-container {
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.75s 0.65s cubic-bezier(0.67, 0.28, 0.47, 1.35);

        svg {
          transform: rotate(0deg);
          transition: all 0.75s 0.65s;
        }
      }
    }
    &.scrolled-before {
      transform: translateY(0px);
      .circle-logo-container {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    &.scrolled-before-active {
      transform: translateY(-60px);
      transition: all 0.5s 0.25s;
      .circle-logo-container {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}

</style>
