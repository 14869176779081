/* Organizes all main menu children items into parent items */

export default {
  computed: {
    mainMenu() {
      const mainMenu = this.apiMenus[6].items;
      const finalArray = [];
      let counter = -1;
      Object.keys(mainMenu).forEach((i) => {
        const objectParent = {
          parent: {},
          children: [],
        };
        if ('post_parent' in mainMenu[i]) {
          if (mainMenu[i].post_parent === 0 && mainMenu[i].post_status === 'publish') {
            counter += 1;
            objectParent.parent = mainMenu[i];
            finalArray.push(objectParent);
          } else if (mainMenu[i].post_status === 'publish') {
            finalArray[counter].children.push(mainMenu[i]);
          }
        }
      });
      return finalArray;
    },
  },
};
