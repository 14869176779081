<template>

<div class="component">
  <section class="text-block-with-large-image" role="region" :aria-label="component.title">
    <div class="ms-row image-row" v-if="component.image.url">
      <div class="col-1-1">
        <img class="image" v-lazy="component.image.url" :alt="component.image.alt">
      </div>
    </div>
    <div class="ms-row title-row" v-if="component.title">
      <div class="col-1-1">
        <h2 class="interior-section-header" v-html="component.title"></h2>
      </div>
    </div>
    <div class="ms-row text-row" v-if="component.text">
      <div class="col-1-1 body-text block-wysiwyg" v-html="component.text">
      </div>
    </div>
    <div class="ms-row" v-if="component.link_list">
      <div class="col-1-1 link-list">
        <ul>
          <li class="link-item"
          v-for="(link, index) in component.link_list"
          :key="index">
            <c-link v-bind="processUrl(link.link_url)">
              {{link.link_text}}
              <ArrowSVG/>
            </c-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>

</template>

<script>

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

export default {
  name: 'TextBlockWLargeImage',
  props: ['processUrl', 'component'],
  components: {
    ArrowSVG,
  },
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/wysiwyg.scss";

.text-block-with-large-image {
  .image-row {
    margin-bottom: 30px;
  }
  .image {
    height: auto;
    max-width: 100%;
  }
  .title-row {
    margin-bottom: 20px;
  }
  .link-list {
    @include bodyText();

    ul {
      list-style: none inside;
    }

    li {
      margin-bottom: 15px;
    }

    a {
      @include linkWithArrow();
      .arrow {
        margin-left: 5px;
      }
    }
  }
}

</style>
