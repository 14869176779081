<template>

<main>
  <h1 id="home-page-title">University of Illinois Chicago</h1>
  <fast-facts/>

  <template v-if="checkStatusLoaded">
    <call-to-action/>
    <div class="ms-grid zero-margin extra-wide-grid full">
      <spotlight-block></spotlight-block>
      <today-block></today-block>
      <key-message2020-block></key-message2020-block>
    </div>
    <campus-reminders/>
  </template>

</main>

</template>

<script>

// Components
import FastFacts from '@/components/ui-components/FastFacts.vue';
import CallToAction from '@/components/ui-components/CallToAction.vue';
import CampusReminders from '@/components/ui-components/CampusReminders.vue';
import SpotlightBlock from '@/components/ui-components/SpotlightBlock.vue';
import KeyMessage2020Block from '@/components/ui-components/KeyMessage2020Block.vue';
// import CallToActionWithTitle from '@/components/ui-components/CallToActionWithTitle.vue';
import TodayBlock from '@/components/ui-components/TodayBlock.vue';

export default {
  name: 'HomeBody',
  components: {
    FastFacts,
    CallToAction,
    CampusReminders,
    SpotlightBlock,
    // CallToActionWithTitle,
    KeyMessage2020Block,
    TodayBlock,
  },
  props: ['checkStatusLoaded'],
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
