<template>

<div class="component">
  <section role="region" class="link-list" :aria-label="component.title">
    <div class="ms-row">
      <div class="col-1-1">
        <h2 class="interior-section-header" v-html="component.title"></h2>
      </div>
    </div>
    <div class="ms-row">
      <div class="col-1-1 text-cell" v-html="component.text">
      </div>
    </div>
    <div class="ms-row link-block-group">

      <c-link class="s-col-1-2 l-col-1-3 link-block-anchor"
      v-for="(item, index) in component.link_group"
      :key="index"
      v-bind="processUrl(item.url)">
        <article class="link-block">
          <h1 class="subhead">
            {{ item.title }}
            <ArrowSVG/>
          </h1>
          <div class="text" v-html="item.text"></div>
        </article>
      </c-link>

    </div>
  </section>
</div>

</template>

<script>

// SVG
import ArrowSVG from '@/components/ui-components/svg/icon-arrow.svg';

export default {
  name: 'LinkList',
  props: ['processUrl', 'component'],
  components: {
    ArrowSVG,
  },
};

</script>

<style scoped lang="scss">

@import "@/scss/shared/link-block-group.scss";

.link-list {
  .interior-section-header {
    margin-bottom: 15px;
  }.text-cell {
    @include bodyText();
  }
}

</style>
