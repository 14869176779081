import { render, staticRenderFns } from "./FastFacts.vue?vue&type=template&id=2dc3aaa3&scoped=true&"
import script from "./FastFacts.vue?vue&type=script&lang=js&"
export * from "./FastFacts.vue?vue&type=script&lang=js&"
import style0 from "./FastFacts.vue?vue&type=style&index=0&id=2dc3aaa3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc3aaa3",
  null
  
)

export default component.exports